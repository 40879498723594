<template>
  <ion-page id="crop-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="false" title="Crop image" :showBack="true" rightTitle="Done" :rightevent="cropImage"></topbar>
      <div class="">
        <vue-cropper ref="cropper" class="cropperItem" :src="$store.state.form.photo" :aspect-ratio="9 / 9"> </vue-cropper>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import $ from "jquery";
export default {
  name: "cropperPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, VueCropper },
  data() {
    return {};
  },
  methods: {
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas);
    },
    async cropImage() {
      const objdata = this.getAuthObj();
      objdata.icon = this.$refs.cropper.getCroppedCanvas().toDataURL();
      objdata.apiUrl = this.apiConfig.updateUserIcon;
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.sendGAEvent("Add profile picture", "Change profile", "profileimage");
      this.$store.state.form.userInfo.icon = data.data.result.icon;
      this.$router.replace({
        path: "/editProfile",
      });
    },
  },
  ionViewDidEnter() {
    this.$store.state.exitApp = false;

    $(".cropperItem").css("height", window.innerHeight - 85 + "px");
    this.$refs.cropper.replace(this.$store.state.form.photo);
    this.sendGAPageView("Crop image");
  },
};
</script>
